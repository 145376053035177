<template>
  <b-modal id="organisation-modal" ref="bmodal" :on-enter-key-press="upsert" hide-footer size="xl" :title="title"
    no-close-on-backdrop no-close-on-esc @hidden="hideOrgModal">
    <template v-slot:modal-title>
      <strong>{{ mode === 'create' ? $t('add') : $t('edit') }}
        {{ $t('building') }}</strong>
    </template>

    <b-overlay :show="loading" rounded="sm">
      <div class="d-block">
        <b-card no-body class="mb-1">
          <b-card-header v-b-toggle.accordion-1 class="p-1 pl-2">
            Building identification (* = required)
          </b-card-header>
          <b-collapse id="accordion-1" visible>
            <b-card-body>
              <b-row>
                <div class="col-md-4 col-sm-12 text-left">
                  <div class="form-group">
                    <label>* {{ $t('organisation') }}:</label>
                    <b-form-input v-model="organisation.name" :readonly="mode === 'edit'"
                      :state="errors.name ? false : null" />
                    <b-form-invalid-feedback v-if="errors.name">
                      {{ errors['name'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 text-left">
                  <div class="form-group">
                    <label>* {{ $t('building') }} ( must start with /
                      ):</label>
                    <b-form-input v-model="organisation.path" :readonly="mode === 'edit'"
                      :state="errors.path ? false : null" />
                    <b-form-invalid-feedback v-if="errors.path">
                      {{ errors['path'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 text-left">
                  <div class="form-group">
                    <label>* {{ $t('displayName') }}:</label>
                    <b-form-input v-model="organisation.displayName" :state="errors.displayName ? false : null" />
                    <b-form-invalid-feedback v-if="errors.displayName">
                      {{ errors['displayName'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header v-b-toggle.accordion-2 class="p-1 pl-2">
            As shown on 'Locations' home page
          </b-card-header>
          <b-collapse id="accordion-2" visible>
            <b-card-body>
              <b-row>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Building Image Url:</label>
                    <b-form-input v-model="organisation.buildingImageUrl"
                      :state="errors.buildingImageUrl ? false : null" />
                    <b-form-invalid-feedback v-if="errors.buildingImageUrl">
                      {{ errors['buildingImageUrl'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Building Address:</label>
                    <b-form-input v-model="organisation.buildingAddress" :state="
  errors.buildingAddress ? false : null
                      " />
                    <b-form-invalid-feedback v-if="errors.buildingAddress">
                      {{ errors['buildingAddress'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Building Information Link Url:</label>
                    <b-form-input v-model="organisation.buildingMoreInfoUrl" :state="
                        errors.buildingMoreInfoUrl ? false : null
                      " />
                    <b-form-invalid-feedback v-if="errors.buildingMoreInfoUrl">
                      {{ errors['buildingMoreInfoUrl'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header v-b-toggle.accordian-menu-links class="p-1 pl-2">
            Menu's and help pages
          </b-card-header>
          <b-collapse id="accordian-menu-links" visible>
            <b-card-body>
              <b-row>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Support / Help Url:</label>
                    <b-form-input v-model="organisation.infoUrl" :state="errors.infoUrl ? false : null" />
                    <b-form-invalid-feedback v-if="errors.infoUrl">
                      {{ errors['infoUrl'] }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 text-left">
                  <b-form-group label="Modules activated for this building:" inline>
                    <b-form-checkbox v-model="organisation.modules.workplace" class="d-inline-block m-1" switch>
                      Reservations
                    </b-form-checkbox>
                    <b-form-checkbox v-model="organisation.modules.sensor" class="d-inline-block m-1" switch>
                      Sensors
                    </b-form-checkbox>
                    <b-form-checkbox v-model="organisation.modules.insights" class="d-inline-block m-1" switch>
                      Insights
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header v-b-toggle.accordion-integrations class="p-1 pl-2">
            Integrations
          </b-card-header>
          <b-collapse id="accordion-integrations" visible>
            <b-card-body>
              <b-row>
                <div class="col-12 text-left">
                  <div class="form-group">
                    <div class="input-group">
                      <b-form-checkbox v-model="
                          organisation.integrations.googleIntegration
                            .enabled
                        " type="number" min="1" max="1000">
                        Enable synchronization of reservations with
                        Google Calendar
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row>
                <div class="col-12 text-left">
                  <div class="form-group">
                    <div class="input-group">
                      <b-form-checkbox v-model="
                          organisation.integrations.azureIntegration
                            .enabled
                        " type="number" min="1">
                        Enable synchronization of reservations with
                        Azure Calendar
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </b-row><b-row>
                <div class="col-12 text-left">
                  <div class="input-group">
                    <b-form-checkbox v-model="
                        organisation.integrations.mapiq.enabled
                      " type="number" min="1">
                      Enable synchronization of sensor data with Mapiq
                    </b-form-checkbox>
                  </div>
                </div>
              </b-row>
              <b-collapse id="accordion-mapiq-options" v-model="organisation.integrations.mapiq.enabled">
                <b-row class="col-12 mt-3 ml-0">
                  <b-form-group class="col-5 mr-1" label="API URL" label-for="mapiq-url">
                    <b-form-input id="mapiq-url" v-model="
                        organisation.integrations.mapiq.baseUrl
                      ">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Username" label-for="mapiq-username" class="col-3 mr-1">
                    <b-form-input id="mapiq-username" v-model="
                        organisation.integrations.mapiq.username
                      ">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Password" label-for="mapiq-password" class="col-3">
                    <b-form-input id="mapiq-password" v-model="
                        organisation.integrations.mapiq.password
                      " type="password">
                    </b-form-input>
                  </b-form-group>
                </b-row>
                <b-row class="col-12 ml-3 mt-2"><strong>Field mappings</strong></b-row>
                <b-row class="col-12 ml-0 mt-2">
                  <b-form-group label="Mapiq sensorId" label-for="mapiqSensorId" class="col-3 mr-1">
                    <b-form-select id="mapiqSensorId" v-model="
                        organisation.integrations.mapiq.mapiqSensorId
                      " :options="mapiqSensorIdOptions" />
                  </b-form-group>
                  <b-form-group label="Mapiq floorId" label-for="mapiqFloorId" class="col-3 mr-1">
                    <b-form-select id="mapiqFloorId" v-model="
                        organisation.integrations.mapiq.mapiqFloorId
                      " :options="mapiqFloorIdOptions" />
                  </b-form-group>
                  <b-form-group class="col-3" label="Mapiq buildingId" label-for="mapiqBuildingId">
                    <b-form-select id="mapiqBuildingId" v-model="
                        organisation.integrations.mapiq
                          .mapiqBuildingId
                      " :options="mapiqBuildingIdOptions" />
                  </b-form-group>
                </b-row>
              </b-collapse>
              <b-row>
                <div class="col-12 text-left mt-3">
                  <div class="input-group">
                    <b-form-checkbox v-model="
                        organisation.integrations.forwardReservation
                          .enabled
                      " type="number" min="1">
                      Enable reservation forwarding to webhooks
                    </b-form-checkbox>
                  </div>
                </div>
              </b-row>
              <b-collapse v-model="
                  organisation.integrations.forwardReservation.enabled
                ">
                <b-table :items="
                    organisation.integrations.forwardReservation
                      .endpoints
                  " :fields="reservationWebhookFields">
                  <template #head(actions)="data">
                    Actions
                    <b-button size="sm" class="ml-3" @click="addReservationWebhook(data)">+ Add</b-button>
                  </template>
                  <template #cell()="data">
                    <b-form-input v-model="data.item[data.field.key]"></b-form-input>
                  </template>
                  <template #cell(actions)="data">
                    <b-button variant="danger" size="sm" @click="removeReservationWebhook(data.index)">remove</b-button>
                  </template>
                </b-table>
              </b-collapse>
              <b-row>
                <div class="col-12 text-left mt-3">
                  <div class="input-group">
                    <b-form-checkbox v-model="
                        organisation.integrations.forward.enabled
                      " type="number" min="1">
                      Enable sensor data forwarding to webhooks
                    </b-form-checkbox>
                  </div>
                </div>
              </b-row>
              <b-collapse v-model="organisation.integrations.forward.enabled">
                <b-table :items="organisation.integrations.forward.endpoints" :fields="sensorDataWebhookFields">
                  <template #head(actions)="data">
                    Actions
                    <b-button size="sm" class="ml-3" @click="addSensorDataWebhook(data)">+ Add</b-button>
                  </template>

                  <template #cell()="data">
                    <b-form-input v-model="data.item[data.field.key]"></b-form-input>
                  </template>
                  <template #cell(actions)="data">
                    <b-button variant="danger" size="sm" @click="removeSensorDataWebhook(data.index)">remove</b-button>
                  </template>
                </b-table>
              </b-collapse>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header v-b-toggle.accordion-reservation-settings class="p-1 pl-2">
            Settings that affect reservations
          </b-card-header>
          <b-collapse id="accordion-reservation-settings" visible>
            <b-card-body>
              <b-row>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>* Anonymise reservations in this building
                      after:</label>
                    <div class="input-group">
                      <b-form-input v-model="organisation.anonymiseAfterDays" type="number" min="1" max="1000" :state="
                          errors.anonymiseAfterDays ? false : null
                        " />

                      <div class="input-group-append">
                        <span class="input-group-text">days</span>
                      </div>
                      <b-form-invalid-feedback v-if="errors.anonymiseAfterDays">
                        {{ errors.anonymiseAfterDays }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>{{ $t('buildingTimezone') }}:</label>
                    <div class="input-group">
                      <b-form-select v-model="
                          organisation.reservationSettings.timezone
                        " :options="timezones" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Workflow for automatic cancellation of
                      no-shows:</label>
                    <div class="input-group">
                      <b-form-select v-model="
                          organisation.reservationSettings.flowName
                        " :options="stateMachines">
                        <template #first>
                          <b-form-select-option :value="null">-- Please select an option
                            --</b-form-select-option>
                        </template></b-form-select>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header v-b-toggle.accordion-3 class="p-1 pl-2">
            Legacy settings (only used for deep links to a building
            i.e. https://:org.okku.io/start/:org/:building)
          </b-card-header>
          <b-collapse id="accordion-3">
            <b-card-body>
              <b-row>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Keycloak Identity Provider Alias (allows
                      forwarding to the SSO provider):</label>
                    <b-form-input v-model="organisation.idpHint" :state="errors.idpHint ? false : null" />
                    <b-form-invalid-feedback v-if="errors.idpHint">
                      {{ errors.idpHint }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 text-left">
                  <div class="form-group">
                    <label>Logo Url:</label>
                    <b-form-input v-model="organisation.logoUrl" :state="errors.logoUrl ? false : null" />
                    <b-form-invalid-feedback v-if="errors.logoUrl">
                      {{ errors.logoUrl }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-overlay>
    <b-row align-h="end">
      <b-button variant="danger" size="lg" class="mt-3 mr-3" @click="hideOrgModal">
        {{ $t('cancel') }}
      </b-button>
      <b-button size="lg" class="mt-3 mr-3" variant="primary" @click="upsert">
        {{ $t('save') }}
      </b-button>
    </b-row>
  </b-modal>
</template>
<script>
import OkkuApi from '@/services/OkkuApi'
import {
  SET_ALERT,
  UPDATE_USER_INFO
} from '@/store/modules/common/mutationTypes'
import momentTZ from 'moment-timezone'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    organisation: {
      type: Object,
      required: true
    },
    organisations: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: {},
      mapiqSensorIdOptions: ['deviceId', 'roomId', 'roomDescription'],
      mapiqFloorIdOptions: ['buildingId', 'buildingDescription'],
      mapiqBuildingIdOptions: ['orgName', 'orgPath'],
      reservationWebhookFields: ['url', 'apiKey', 'actions'],
      sensorDataWebhookFields: [
        'url',
        'headerName',
        'headerValue',
        'actions'
      ],
      stateMachines: [],
      loading: false
    }
  },
  computed: {
    timezones() {
      return momentTZ.tz.names()
    },
    mode() {
      return this.organisation.id ? 'edit' : 'create'
    },
    title() {
      return `${!this.organisation.id ? this.$t('add') : this.$t('edit')
        } ${this.$t('building')}`
    },
    forwardReservations() {
      if (!this.organisation.integrations.forwardReservation)
        return false
      return this.organisation.integrations.forwardReservation.enabled
    },
    forwardSensorData() {
      if (!this.organisation.integrations.forward) return false
      return this.organisation.integrations.forward.enabled
    }
  },
  async mounted() {
    this.stateMachines = await OkkuApi.getStateMachines()
  },
  methods: {
    addReservationWebhook() {
      this.organisation.integrations.forwardReservation.endpoints.push(
        { url: null, apiKey: null }
      )
    },
    removeReservationWebhook(index) {
      this.organisation.integrations.forwardReservation.endpoints.splice(
        index,
        1
      )
    },
    addSensorDataWebhook() {
      this.organisation.integrations.forward.endpoints.push({
        url: null,
        headerName: null,
        headerValue: null
      })
    },
    removeSensorDataWebhook(index) {
      this.organisation.integrations.forward.endpoints.splice(
        index,
        1
      )
    },
    isFormDataValid({
      anonymiseAfterDays,
      dashboardVersion,
      displayName,
      name,
      path
    }) {
      const errors = {}
      if (!this.organisation.id) {
        this.organisations.forEach(
          ({ displayName: orgDisplayName, path: orgPath }) => {
            if (
              this.organisation.displayName &&
              orgDisplayName === this.organisation.displayName.trim()
            ) {
              errors.displayName =
                'Building with this name already exists'
            }
            if (
              this.organisation.displayName &&
              orgDisplayName ===
              this.organisation.displayName.trim() &&
              orgPath === this.organisation.path.trim()
            ) {
              errors.path = 'Building with this path already exists'
            }
          }
        )
      }

      if (
        !displayName ||
        (displayName && displayName.trim().length < 1) ||
        (displayName && displayName.trim().length > 100)
      ) {
        errors.displayName =
          'Display name must be 1-100 characters long'
      }

      if (!anonymiseAfterDays || anonymiseAfterDays < 1) {
        errors.anonymiseAfterDays =
          'Anonymise values should be greater than zero'
      } else if (
        anonymiseAfterDays &&
        anonymiseAfterDays.length > 4
      ) {
        errors.anonymiseAfterDays =
          'Anonymise values should not be greater than 4 digits'
      }

      if (!name || (name && name.trim().length) < 3) {
        errors.name = 'Name must at least 3 characters long'
      }

      if (!path) {
        errors.path = 'Path must start with / and 4 characters long'
      } else if (
        path.trim().length < 4 ||
        path[0].toString() !== '/'
      ) {
        errors.path = 'Path must start with / and 4 characters long'
      }

      if (Object.entries(errors).length) {
        this.errors = { ...errors }
        return false
      }
      return true
    },
    hideOrgModal() {
      this.errors = {}
      this.$bvModal.hide('organisation-modal')
    },
    upsert(bvModalEvt) {
      const orgData = { ...this.organisation }
      orgData.dashboardVersion = 2

      if (!this.isFormDataValid(orgData)) {
        if (
          this.errors.path ||
          this.errors.name ||
          this.errors.displayName
        ) {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: this.$t('buildingIdentificationErrors'),
            variant: 'danger'
          })
        }
        return
      }

      if (orgData.logoUrl === '') orgData.logoUrl = null
      if (orgData.infoUrl === '') orgData.infoUrl = null

      this.loading = true
      OkkuApi.upsertBuilding(orgData)
        .then(data => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 1,
            text: this.$t('changesSaved'),
            variant: 'success'
          })
          this.$store.commit(`common/${UPDATE_USER_INFO}`, orgData)
          this.$bvModal.hide('organisation-modal')
          this.loading = false
          if (this.action === 'create') {
            setTimeout(() => {
              this.$router.push('/workplace')
            }, 1200)
          }
        })
        .catch(response => {
          this.loading = false
          if (response && response.data.errors) {
            this.errors = {}
            response.data.errors.forEach(error => {
              this.errors[error.param] = error.msg
            })
          } else {
            this.$store.commit(`common/${SET_ALERT}`, {
              seconds: 3,
              text: response.data.message,
              variant: 'danger'
            })
          }
        })
    }
  }
}
</script>
<style>
.nav-link {
  color: white;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6 !important;
  color: white;
}
</style>
