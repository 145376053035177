<template>
  <div class="page-container">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-10 col-sm-12">
          <alert />
          <h1 class="title-1 pt-4" style="margin-top: 6px;">
            {{ $t('buildings') }}
          </h1>
          <b-overlay :show="loading" rounded="sm">
            <organisation-table
              :items="organisations"
              :columns="columns"
              :actions="actions"
              @edit="editOrganisation"
              @import="$refs.file.click()"
              @export="exportOrganisation"
              @delete="
                data => {
                  organisation = data
                  $bvModal.show('modal-remove')
                }
              "
              @create="createOrganisation"
            />
          </b-overlay>
        </div>
      </div>
    </div>
    <input
      ref="file"
      type="file"
      style="display: none"
      @input="importOrganisation"
    />
    <organisation-modal
      :is="buildingModal"
      :action="action"
      :organisation="organisation"
      :organisations="organisations"
      @done="getBuildings"
    />
    <b-modal
      id="modal-remove"
      :on-enter-key-press="() => deleteOrganisation(organisation)"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteOrgConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          style="margin-left:10px"
          class="mt-1"
          primary
          @click="deleteOrganisation(organisation)"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _kebabKase from 'lodash/kebabCase'
import OkkuApi from '@/services/OkkuApi'
import {
  DELETE_ORG,
  SET_ALERT,
  SET_USER_INFO
} from '@/store/modules/common/mutationTypes'
import { mapGetters } from 'vuex'

import Alert from '@/components/common/Alert'
import OrganisationTable from '@/components/common/Table'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import OrganisationModal from '@/components/modals/OrganisationModal'

export default {
  name: 'BuildingsPage',
  components: {
    OrganisationTable,
    OrganisationModal,
    Alert,
    'b-modal': BootstrapModalHOC
  },
  data() {
    return {
      action: '',
      organisations: [],
      columns: [
        {
          class: '',
          header: 'displayName',
          key: 'displayName'
        },
        {
          class: '',
          header: 'orgName',
          key: 'name'
        },
        {
          class: '',
          header: 'orgPath',
          key: 'path'
        }
      ],
      actions: [
        {
          name: 'add',
          global: true,
          class: 'btn btn-secondary',
          event: 'create',
          icon: 'plus'
        },
        {
          name: 'import',
          global: true,
          class: 'btn btn-secondary',
          event: 'import',
          icon: 'file-import'
        },
        {
          name: 'edit',
          class: 'action link-edit text-primary',
          event: 'edit',
          icon: 'edit'
        },
        {
          name: 'export',
          class: 'action link-edit text-primary',
          event: 'export',
          icon: 'file-export'
        },
        {
          name: 'remove',
          class: 'action link-remove',
          event: 'delete',
          icon: 'times'
        }
      ],
      organisation: {},
      buildingModal: undefined,
      loading: false
    }
  },
  computed: {
    ...mapGetters('common', ['isDashboardAdmin'])
  },
  mounted() {
    this.getBuildings()
  },
  methods: {
    async getBuildings() {
      const buildings = await OkkuApi.getBuildings()
      this.organisations = buildings
      if (!this.isDashboardAdmin) {
        await this.refreshUserInfo()
      }
    },
    async refreshUserInfo() {
      const myBuildings = await OkkuApi.getMyBuildings()
      this.$store.commit(`common/${SET_USER_INFO}`, myBuildings)
    },
    createOrganisation() {
      this.action = 'create'
      this.organisation = {
        path: '/',
        modules: {
          sensor: false,
          workplace: true,
          insights: false
        },
        reservationSettings: {
          timezone: 'Europe/Amsterdam',
          flowName: undefined,
          policies: [
            'disable-overlapping-reservation',
            'daily-maximum',
            'allowed-timeslot',
            'future-day-max'
          ],
          timeslot: false,
          staticQrCode: false,
          futureDayMaximum: 7,
          totalReservationsLimit: 0,
          dailyMaximum: 0,
          buildingMaximum: 0,
          defaultPeriod: 60,
          reservationTimeLimit: 0,
          earlyEntryMinutes: 15
        },
        integrations: {
          mapiq: {
            baseUrl: 'https://acmecorp.mapiq.net',
            username: '',
            password: '',
            mapiqSensorId: 'roomDescription',
            mapiqFloorId: 'buildingId',
            mapiqBuildingId: 'orgPath',
            enabled: false
          },
          forwardReservation: {
            endpoints: [],
            enabled: false
          },
          forward: {
            endpoints: [],
            enabled: false
          },
          googleIntegration: {
            enabled: false
          },
          azureIntegration: {
            enabled: false
          }
        }
      }
      this.buildingModal = OrganisationModal
      this.$nextTick(function showModal() {
        this.$bvModal.show('organisation-modal')
      })
    },
    editOrganisation(organisation) {
      this.action = 'edit'
      // fix old data
      if (!organisation.integrations.googleIntegration) {
        organisation.integrations.googleIntegration = {
          enabled: false
        }
        organisation.integrations.azureIntegration = {
          enabled: false
        }
      }
      if (!organisation.integrations.forwardReservation) {
        organisation.integrations.forwardReservation = {
          enabled: false
        }
      }

      this.buildingModal = OrganisationModal
      this.organisation = organisation
      this.$nextTick(function showModal() {
        this.$bvModal.show('organisation-modal')
      })
    },
    importOrganisation() {
      const file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', file)
      OkkuApi.importBuilding(formData)
        .then(data => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('importSuccess'),
            variant: 'success'
          })
          this.getBuildings()
        })
        .catch(() => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('importError'),
            variant: 'danger'
          })
        })
        .then(() => {
          this.$refs.file.value = ''
        })
    },
    exportOrganisation(organisation) {
      OkkuApi.exportBuilding(organisation.id)
        .then(data => {
          const url = window.URL.createObjectURL(
            new Blob([JSON.stringify(data)])
          )
          const link = document.createElement('a')
          link.href = url
          const filename = `${_kebabKase(
            organisation.displayName
              ? organisation.displayName
              : `${organisation.name}-${organisation.path}`
          )}.json`
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('exportSuccess'),
            variant: 'success'
          })
        })
        .catch(() => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('exportError'),
            variant: 'danger'
          })
        })
    },
    deleteOrganisation(organisation) {
      this.$bvModal.hide('modal-remove')
      this.loading = true
      return OkkuApi.deleteBuilding(organisation.id, true)
        .then(data => {
          this.$store.commit(`common/${DELETE_ORG}`, organisation)
          this.loading = false
          this.getBuildings().then(
            this.$store.commit(`common/${SET_ALERT}`, {
              seconds: 3,
              text: this.$t('deleteSuccess'),
              variant: 'success'
            })
          )
        })
        .catch(error => {
          this.loading = false
          let { message } = error
          if (error.response && error.response.data) {
            message = error.response.data
          }
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: message,
            variant: 'danger'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
